import React, { useEffect, useState } from "react";
import { Image } from "react-bootstrap";

function Skills() {
  const [skills, setSkills] = useState([]);

  useEffect(() => {
    const fetchSkills = async () => {
      try {
        const response = await fetch("/data/skills.json");
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        const data = await response.json();
        setSkills(data);
      } catch (error) {
        console.error("Error fetching skills:", error);
      }
    };

    fetchSkills();
  }, []);

  return (
    <div className="technologies text-center">
      <h2 className="my-4">Skills</h2>
      <div className="row mb-4 gap-4">
        {skills.map((skill, index) => (
          <div
            key={index}
            className="col d-flex flex-column align-items-center"
          >
            <div className="skill-card d-flex flex-column justify-content-center align-items-center gap-3">
              <a target="_blank" rel="noreferrer" href={skill.link}>
                <Image
                  className="skill-icons"
                  alt="skill-icon"
                  src={skill.imageUrl}
                />
              </a>
              <div className="skill-name">{skill.name}</div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default Skills;
