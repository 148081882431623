import React from "react";
import { format } from "date-fns";
import { BsGithub, BsLinkedin, BsTwitter } from "react-icons/bs";
import { BiCopyright } from "react-icons/bi";

function Footer() {
  const currentDate = new Date();

  return (
    <div className="container">
      <div className="row d-flex justify-content-between py-5">
        <div className="col d-flex align-items-center footer-name">
          <div>{format(currentDate, "Y")}</div>
          <div className="d-flex align-items-center mx-1 ">
            <BiCopyright />
            <div className=" ">Andy Cotruta</div>
          </div>
        </div>
        <div className="col d-flex justify-content-end gap-4 w-30 footerSocialLinks">
          <a
            target="_blank"
            rel="noreferrer"
            href="https://github.com/AndyCotruta"
          >
            <BsGithub className="social-media-icons" />
          </a>

          <a
            target="_blank"
            rel="noreferrer"
            href="https://www.linkedin.com/in/andy-aristide-cotruta-03b7a4136/"
          >
            <BsLinkedin className="social-media-icons" />
          </a>

          <a
            target="_blank"
            rel="noreferrer"
            href="https://twitter.com/CotrutaAndy"
          >
            <BsTwitter className="social-media-icons" />
          </a>
        </div>
      </div>
    </div>
  );
}

export default Footer;
