import React from "react";
// import { Carousel, CarouselItem } from "react-bootstrap";
import { BsArrowUpRight } from "react-icons/bs";

function Testimonials() {
  return (
    <div className="testimonials pt-4 d-flex flex-column align-items-center">
      {/* <h2 className="text-center">Testimonials</h2>
      <Carousel>
        <CarouselItem>
          <div className="testimonialText">
            "Working with Andy was an absolute pleasure. He is an exceptional
            full stack web developer who is extremely passionate about web and
            mobile applications. He helped me develop my e-commerce website from
            start to finish, and the end result was beyond my expectations.
            Andy's attention to detail and expertise in animations truly brought
            my website to life. I highly recommend him to anyone looking for a
            talented and dedicated web developer."
          </div>
          <h5 className="text-center mt-5 mb-3">Charles Darwin</h5>
          <div className="text-center">
            Owner of<span className="text-blue">Blue Text</span>
          </div>
        </CarouselItem>
        <CarouselItem>
          <div className="testimonialText">
            "I had the pleasure of working with Andy on a complex mobile
            application project, and I can confidently say that he is one of the
            best developers I have ever worked with. He has a strong passion for
            developing innovative applications that are user-friendly and
            engaging. His expertise in full stack development and his knowledge
            of mobile application development truly set him apart. Andy's
            dedication and work ethic are second to none. I highly recommend him
            for any project."
          </div>
          <h5 className="text-center mt-5 mb-3">Charles Darwin</h5>
          <div className="text-center">
            Full stack developer at<span className="text-blue">Epicode</span>
          </div>
        </CarouselItem>
        <CarouselItem>
          <div className="testimonialText">
            "Andy is an incredibly talented full stack web developer who has a
            passion for creating visually stunning and high-performing web
            applications. He helped me develop my company's website, and his
            attention to detail and expertise in animations made a huge
            difference in the end result. Andy is an excellent communicator and
            worked tirelessly to ensure that my vision was brought to life. I
            highly recommend him to anyone looking for a top-notch web
            developer."
          </div>
          <h5 className="text-center mt-5 mb-3">Charles Darwin</h5>
          <div className="text-center">
            Owner at<span className="text-blue">Blue Text</span>
          </div>
        </CarouselItem>
      </Carousel> */}
      <a href="tel:+4915259654844" className="call-link">
        <div className="callAndy">
          <div>
            <div className="describe">DESCRIBE YOUR</div>
            <div className="describe">PROJECT</div>
            <p className="call">Call</p>
            <p className="call">Andy</p>
            <BsArrowUpRight />
          </div>
        </div>
      </a>
    </div>
  );
}

export default Testimonials;
