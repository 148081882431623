import React from "react";
import AboutMe from "./AboutMe";
import Education from "./Education";
import HeroSection from "./HeroSection";
import MainTitle from "./MainTitle";
import Projects from "./Projects";
import Skills from "./Skills";
import Testimonials from "./Testimonials";

function Homepage() {
  return (
    <div className="container">
      <MainTitle />
      <HeroSection />
      <AboutMe />
      <Skills />
      <Education />
      <Projects />
      <Testimonials />
    </div>
  );
}

export default Homepage;
