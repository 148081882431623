import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { useLocation } from "react-router";

function ProjectDetails() {
  const location = useLocation();
  const [project, setProject] = useState();

  useEffect(() => {
    if (location.state.project) {
      localStorage.setItem(
        "currentProject",
        JSON.stringify(location.state.project)
      );
      const currentProject = JSON.parse(localStorage.getItem("currentProject"));
      setProject(currentProject);
    }
  }, [location.state.project]);

  return (
    <div className="container">
      {project && (
        <>
          <h1 className="text-center pt-4">{project.title}</h1>
          <div className="project-description pt-3 pb-4">
            <img
              className="project-image"
              src={project.image}
              alt={project.ProjectDetails}
            />
            <div>{project.description}</div>
          </div>
          <div className="d-flex justify-content-around gap-4 projectLinks py-2">
            <a target="_blank" rel="noreferrer" href={project.repo}>
              <div className="projectRepo">REPO</div>
            </a>
            <a target="_blank" rel="noreferrer" href={project.demo}>
              <div className="projectDemo">DEMO</div>
            </a>
          </div>
          <div className="project-technologies py-3">
            <h2 className="text-center py-2">Technologies used</h2>
            <div className="row d-flex justify-content-between">
              {project.technologies.map((technology, i) => (
                <div
                  key={i}
                  className="col d-flex flex-column align-items-center"
                >
                  <img
                    className="technology-logo"
                    src={technology.logo}
                    alt="technology logo"
                  />
                  <div className="technology-title">{technology.title}</div>
                </div>
              ))}
            </div>
          </div>
        </>
      )}
    </div>
  );
}

export default ProjectDetails;
