import React from "react";
import { useNavigate } from "react-router-dom";

function ProjectCard({ project }) {
  const navigate = useNavigate();

  return (
    <div className="col-xs-12 col-md-6 d-flex flex-column align-items-center project-card">
      <div className="projectTitle">{project.title}</div>
      <div className="proj">
        <div
          className="project-details"
          onClick={() => {
            navigate("/project-details", {
              state: { project },
            });
          }}
        >
          Details
        </div>
        <img className="projectImg" src={project.image} alt="" />
      </div>

      <div className="d-flex gap-4 projectLinks">
        <a target="_blank" rel="noreferrer" href={project.repo}>
          <div className="projectRepo">REPO</div>
        </a>

        <a target="_blank" rel="noreferrer" href={project.demo}>
          <div className="projectDemo">DEMO</div>
        </a>
      </div>
    </div>
  );
}

export default ProjectCard;
