import React from "react";
import { Col, Row } from "react-bootstrap";
import EdExpCard from "./EdExpCard";

function Education() {
  return (
    <div className="my-4 education">
      <h2 className="text-center">Education & Experience</h2>
      <Row className="mt-4 text-center">
        <Col>
          <div className="text-start year">2013</div>
          <div className="text-start">
            <EdExpCard
              graduated={"Bio-Chemistry"}
              location={"National College 'Petru Rares', Suceava, RO"}
            />
            <EdExpCard graduated={"Visual Artist"} location={"Freelancer"} />
          </div>
        </Col>
        <Col>
          <div className="text-center year">2013-2020</div>
          <div>
            <EdExpCard
              graduated={"General Medicine"}
              location={
                "University of Medicine and Pharmacy 'Gr.T.Popa', Iasi, RO"
              }
            />
            <EdExpCard
              graduated={"Client Services"}
              location={"SC Almaviva Services, IDEO, Iasi, RO"}
            />
            <EdExpCard
              graduated={"Amazon Delivery Associate"}
              location={"OMLG Gmbh, Berlin, DE"}
            />
          </div>
        </Col>
        <Col>
          <div className="text-end year">2020-PRESENT</div>
          <div className="text-end">
            <EdExpCard
              graduated={"Junior Project Manager"}
              location={"Clearsky Network, Iasi, RO"}
            />
            <EdExpCard
              graduated={"Computer Science"}
              location={"Internationale Hochschule Berlin, DE"}
            />
            <EdExpCard
              graduated={"Full Stack Developer"}
              location={"Epicode Global, DE"}
            />
            <EdExpCard
              graduated={"Software Engineer"}
              location={"Bandel Automobiltechnik GmbH, DE"}
            />
          </div>
        </Col>
      </Row>
    </div>
  );
}

export default Education;
