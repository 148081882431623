import React, { useState } from "react";
import { format } from "date-fns";

function HeroSection() {
  const currentDate = new Date();

  const [imageLoaded, setImageLoaded] = useState(false);

  const handleImageLoad = () => {
    setImageLoaded(true);
  };

  return (
    <div id="hero" className="row d-flex justify-content-between my-5">
      <div className="col d-flex flex-column justify-content-between py-5 w-30">
        <div className="mb-2">
          <div className="hero-titles pb-3">PERSONAL QUOTE</div>
          <div className="hero-text">
            Work for money and
            <br /> code for love! <br />
            Hi, I am Andy
            <br /> and I am a full stack developer.
          </div>
        </div>
        <div className="mb-2">
          <div className="hero-titles pb-3">CONTACT</div>
          <div className="hero-text">
            <div>Itzehoe, Germany</div>
            <a
              href="mailto: andyaristidecotruta@gmail.com"
              className="contact-links"
            >
              <div>andyaristidecotruta@gmail.com</div>
            </a>
            <a href="tel:+4915259654844" className="contact-links">
              <div>+4915259654844</div>
            </a>
          </div>
        </div>
        <div className="mb-2">
          <div className="hero-titles pb-3">SERVICES </div>
          <div className="hero-text">
            <div>Web Applications</div>
            <div>Mobile Applications</div>
            <div>Animations</div>
          </div>
        </div>
      </div>
      <div className="col d-flex justify-content-center blur-load">
        <img
          src={imageLoaded ? "andy-low.jpeg" : "andy-extra-low.jpeg"}
          alt="a young man wearing a yellow shirt, looking straight at he camera and smilling."
          className={
            imageLoaded ? "my-pic no-gutters" : "my-pic no-gutters my-pic-blur"
          }
          onLoad={handleImageLoad}
          loading="lazy"
          style={{
            opacity: imageLoaded ? 1 : 0,
            transition: "opacity 0.5s ease-in",
          }}
        />
      </div>

      <div className="col d-flex flex-column justify-content-between py-5 w-30 text-end">
        <div className="h-25">
          <div className="hero-titles pb-3">YEARS OF EXPERIENCE</div>
          <div className="hero-numbers">{format(currentDate, "Y") - 2022}</div>
        </div>
        <div className="h-25">
          <div className="hero-titles pb-3">CLIENT SATISFACTION</div>
          <div className="hero-numbers">100%</div>
        </div>
        <div className="h-25">
          <div className="hero-titles pb-3">WORLDWIDE CLIENTS</div>
          <div className="hero-numbers">1</div>
        </div>
        <div className="mb-2">
          <div className="hero-titles pb-3">PROJECTS DONE</div>
          <div className="hero-numbers">1</div>
        </div>
      </div>
    </div>
  );
}

export default HeroSection;
