import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import NavbarComponent from "./components/Navbar.jsx";
import Footer from "./components/Footer";
import { Helmet } from "react-helmet";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Homepage from "./components/Homepage";
import ProjectDetails from "./components/ProjectDetails";

function App() {
  return (
    <div className="body">
      <Helmet>
        <meta charSet="utf-8" />
        <title>Andy Cotruta</title>
        <link rel="canonical" href="http://andycotruta.com" />
        <meta name="description" content="Andy Cotruta portfolio website" />
      </Helmet>
      <BrowserRouter>
        <NavbarComponent />
        <Routes>
          <Route path="/" element={<Homepage />} />
          <Route path="/project-details" element={<ProjectDetails />} />
        </Routes>
        <Footer />
      </BrowserRouter>
    </div>
  );
}

export default App;
