import React from "react";

function MainTitle() {
  return (
    <div className="text-center mainTitle pt-4">
      <h1>Andy Aristide Cotruta</h1>
      <div className="epicode-student">Full Stack Developer</div>
    </div>
  );
}

export default MainTitle;
