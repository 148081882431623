import React from "react";

function EdExpCard({ graduated, location }) {
  return (
    <div className="my-4">
      <div className="graduated">{graduated}</div>
      <div className="location">{location}</div>
    </div>
  );
}

export default EdExpCard;
