import { Nav, Navbar } from "react-bootstrap";
import { BsGithub, BsLinkedin, BsTwitter } from "react-icons/bs";
import { useLocation, useNavigate } from "react-router-dom";

function NavbarComponent() {
  const navigate = useNavigate();
  const location = useLocation();

  return (
    <div className="main-nav container">
      <Navbar
        expand="lg"
        className="d-flex justify-content-between align-items-center w-100"
      >
        <div
          className="brand"
          onClick={() => {
            navigate("/");
          }}
        >
          <img className="newLogo" src="newLogo.png" alt="My Logo" />
        </div>
        <Navbar.Toggle className="controls" aria-controls="basic-navbar-nav" />
        <Navbar.Collapse>
          <div className="d-flex justify-content-between align-items-center w-100">
            <div className=" d-flex w-30 navItems d-flex gap-4 nav-collapse-items">
              <Nav.Link
                href=""
                className="fw-bold "
                onClick={() => {
                  navigate("/");
                }}
              >
                Home
              </Nav.Link>
              <Nav.Link
                href="#projects"
                className="fw-bold "
                onClick={() => {
                  if (location.pathname !== "/") {
                    navigate("/");
                  } else return;
                }}
              >
                My Work
              </Nav.Link>
              <Nav.Link
                href="#hero"
                className="fw-bold"
                onClick={() => {
                  if (location.pathname !== "/") {
                    navigate("/");
                  } else return;
                }}
              >
                Contact
              </Nav.Link>
            </div>
            <div className="col flex-grow-1 text-center logo">
              <img className="newLogo" src="newLogo.png" alt="My Logo" />
            </div>
            <div className="col navbarSocialLinks">
              <a
                target="_blank"
                rel="noreferrer"
                href="https://github.com/AndyCotruta"
              >
                <BsGithub className="social-media-icons" />
              </a>

              <a
                target="_blank"
                rel="noreferrer"
                href="https://www.linkedin.com/in/andy-aristide-cotruta-03b7a4136/"
              >
                <BsLinkedin className="social-media-icons" />
              </a>

              <a
                target="_blank"
                rel="noreferrer"
                href="https://twitter.com/CotrutaAndy"
              >
                <BsTwitter className="social-media-icons" />
              </a>
            </div>
          </div>
        </Navbar.Collapse>
      </Navbar>
    </div>
  );
}

export default NavbarComponent;
