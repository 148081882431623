import React from "react";

function AboutMe() {
  return (
    <div className="text-center mb-4">
      <h2 className="my-4">About Me</h2>
      <p>
        Hi, I'm Andy, a full stack software engineer at Bandel Automobiltechnik
        GmbH. Specializing in C#, .NET, and MSSQL, I am passionate about
        continuously honing these skills and staying abreast of the latest
        technologies, including Next.js and React Native, through personal
        projects.
      </p>
      <p>
        I believe in clean, maintainable code and value comprehensive planning
        and clear requirements in professional projects. My goal is to work in a
        highly professional environment with structured teams, tackling
        large-scale projects that use cutting-edge technology.
      </p>
      <p>
        Beyond coding, I'm passionate about art and graphics, which enhances my
        eye for front-end development and makes me particularly enthusiastic
        about FE projects.
      </p>
      <p>Let's build something great together!</p>
    </div>
  );
}

export default AboutMe;
